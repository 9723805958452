<template>
  <FimButton v-bind="{ ...$props, ...$attrs, to, target }">
    <template v-if="$slots.badge" #badge>
      <slot name="badge" />
    </template>
    <template v-if="$slots.icon" #icon>
      <slot name="icon" />
    </template>
    <template v-if="$slots.iconAfter" #iconAfter>
      <slot name="iconAfter" />
    </template>

    <slot>{{ blok.cta_label }}</slot>
  </FimButton>
</template>

<script setup lang="ts">
import type { SbLink } from '../types/storyblok'
import { getLinkTarget, resolvSbLink } from '../utils/links'
import FimButton from '~/components/ui/buttons/FimButton.vue'

const props = defineProps({
  blok: {
    type: Object as PropType<SbLink>,
    required: true,
  },
})

const $currentShop = useCurrentShop()
const to = computed(() => resolvSbLink(props.blok, $currentShop))
const target = getLinkTarget(props.blok)
</script>
